import { ActionType } from "typesafe-actions";

import * as CustomerAction from "./customerActions";
import * as CustomerBranchAction from "./customerBranchActions";

type Actions = typeof CustomerAction & typeof CustomerBranchAction;

export type CustomerActions = ActionType<Actions>;

export * from "./customer.types";
export * from "./customerActions";
export * from "./customerBranchActions";
export * from "./customerReducer";
export * from "./defaultState";
