import { ICustomerState } from "./customer/customer.types";
import { ICustomizerState } from "./customizer/customizerReducer";
import { IMessagesState } from "./messages/messages.types";
import { initialMessagesState } from "./messages/messagesState";
// import { IAutomobilePolicyRiskLocationState, defaultAutomobilePolicyRiskLocationState } from "./automobilePolicyRiskLocation/automobilePolicyRiskLocation.types";
import { ICommonState, defaultCommonState } from "./common/common.types";

import { IUsersState } from "./UserProfileList/userProfile.types";
import { initialUserProfileState } from "./UserProfileList/userprofileState";

import { IDataManagementState } from "./DataManagement/dataManagement.types";
import { defaultDataManagementState } from "./DataManagement/defaultState";
import { IApprovalQuoteState } from "./approval/quote";
import { defaultApprovalQuoteState } from "./approval/quote/defaultState";
import { ICommentState, defaultCommentState } from "./comments";
import { ICompanyState, initialCompanyState } from "./company/company.types";
import { IContactsState, initialContactState } from "./contacts/contacts.types";
import { ICostingSheetState } from "./costingSheet";
import { defaultCostingSheetState } from "./costingSheet/defaultState";
import { defaultCustomerState } from "./customer";
import { IHistoryState, initialHistoryState } from "./history";
import { initialManageSiteState } from "./manageSite/defaultState";
import { IManageSiteState } from "./manageSite/manageSite.types";
import {
  IOpportunityState,
  inititalOpportunityState,
} from "./opportunites/opportunites.types";
import {
  IProductsState,
  initialProductsState,
} from "./products/products.types";
import { initialProjectState } from "./projects/defaultState";
import { IProjectState } from "./projects/project.types";
import { defaultQuoteState } from "./quote/defaultState";
import { IQuoteState } from "./quote/quote.types";
import { defaultRenderingState } from "./rendering/defaultState";
import { IRenderingState } from "./rendering/rendering.types";
import { initialScheduleTaskState } from "./scheduleTask/defaultState";
import { IScheduleTaskState } from "./scheduleTask/scheduleTask.types";
import {
  ISecurityState,
  initialSecurityState,
} from "./security/security.types";
import {
  ITaskBoardState,
  initialTaskBoardState,
} from "./taskBoard/taskBoard.types";
import { ITaskCalenderState, initialTaskCalenderState } from "./taskCalender/tasksCalender.types";
import { initialTaskCategoryState } from "./taskCategory/defaultState";
import { ITaskCategoryState } from "./taskCategory/taskCategory.types";
import { initialTaskListState } from "./taskList/defaultState";
import { ITaskListState } from "./taskList/taskList.types";
import { IProjectTeamState } from "./projectTeam/projectTeam.types";
import { defaultProjectTeamState } from "./projectTeam/defaultState";

export interface IDropdownListType {
  key: string;
  text: string;
  value: string;
}

export interface IStoreState {
  customizerReducer: ICustomizerState;
  customer: ICustomerState;
  message: IMessagesState;

  userprofile: IUsersState;
  common: ICommonState;

  opportunites: IOpportunityState;
  company: ICompanyState;
  contacts: IContactsState;
  products: IProductsState;
  quotes: IQuoteState;
  costingSheet: ICostingSheetState;
  taskBoard: ITaskBoardState;
  security: ISecurityState;
  rendering: IRenderingState;
  dataManagement: IDataManagementState;
  approvalQuotes: IApprovalQuoteState;
  comment: ICommentState;
  history: IHistoryState;
  projects: IProjectState;
  projectTeam: IProjectTeamState;
  scheduleTask: IScheduleTaskState;
  taskList: ITaskListState;
  calender: ITaskCalenderState;
  taskCategory: ITaskCategoryState;
  manageSite: IManageSiteState;
}

export const initialStoreState: IStoreState = {
  customizerReducer: {
    activeDir: "ltr",
    activeNavbarBg: "#0b70fb", // This can be any color,
    activeSidebarBg: "#ffffff", // This can be any color
    activeMode: "light", // This can be light or dark
    activeTheme: "ROCKWORTH_THEME", // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME
    SidebarWidth: 240,
  },

  customer: defaultCustomerState,
  message: initialMessagesState,

  common: defaultCommonState,

  userprofile: initialUserProfileState,

  opportunites: inititalOpportunityState,
  company: initialCompanyState,
  contacts: initialContactState,
  products: initialProductsState,
  quotes: defaultQuoteState,
  costingSheet: defaultCostingSheetState,
  taskBoard: initialTaskBoardState,
  security: initialSecurityState,
  rendering: defaultRenderingState,
  dataManagement: defaultDataManagementState,
  approvalQuotes: defaultApprovalQuoteState,
  comment: defaultCommentState,
  history: initialHistoryState,
  projects: initialProjectState,
  projectTeam: defaultProjectTeamState,
  scheduleTask: initialScheduleTaskState,
  taskList: initialTaskListState,
  taskCategory: initialTaskCategoryState,
  calender: initialTaskCalenderState,
  manageSite: initialManageSiteState
};
